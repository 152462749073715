import React from 'react';
import ReactDOM from 'react-dom/client';
import Layout from './layout/layout';
import App from './App';
import AuthContextProvider from './contexts/authcontexts';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import "@fontsource/dosis/500.css";
import "@fontsource/poppins/600.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <Layout>
          <App />
        </Layout>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

