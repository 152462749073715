import React, {useState, useContext, useEffect} from "react";
import SignIn from "../authentication/signin";
import SignOut from "../authentication/signout";
import ImageViewer from "./imageviewer";
import UpdatePassword from "../authentication/updatepassword";
import {AuthContext} from "../contexts/authcontexts";
import {firestoreDB} from "../firebase";
import {getDoc, doc} from "firebase/firestore";

export default function Dashboard(props) {

  const {isOnline} = useContext(AuthContext);
  const [userDB, setUserDB] = useState("");

  useEffect(() => {
    async function tarikData(){ 
      const userData = await getDoc(doc(firestoreDB, "adminsteachers", isOnline ));
      if(userData.exists()){
        setUserDB(userData.data());
      }else{console.log("profile not found")}}
    tarikData();
  }, [isOnline])

  return (
    <div id="dashboard" className={props.box}>
      <h1>KINDERGYM INDONESIA</h1>
      <h3>TEACHERS ACCESS</h3>
      {isOnline?
        <div id="dashboardContent">
          <div>
            <ImageViewer/>
            <p>{userDB.name}</p>
            <p>{userDB.role}</p>
          </div>
          <div>
            <UpdatePassword/>
            <SignOut/>
          </div> 
        </div> :
        <div><SignIn/></div> 
      }
    </div>
  )
}
