import {styled, createTheme, alpha} from '@mui/material/styles';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';

export const theme = createTheme({
  palette: {
    secondary: {
      main: alpha("#EEF1FF", 0.8),
    },
  },
});

export const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  }));

export const StyledTableRow = styled(TableRow)(({theme}) => ({
  [`&.${tableRowClasses.root}`]: {
    //style here  
  }
  }));