import React, {useContext} from "react";
import {AuthContext} from "../contexts/authcontexts";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function SignIn(props) {

const {username, password, handleSignIn} = useContext(AuthContext);

return (
  <div id="signin">
    <TextField inputRef={username} error variant="outlined" label="Username"/>
    <TextField inputRef={password} error variant="outlined" label="password" type="password"/>
    <Button variant="contained" onClick={handleSignIn}><h2> SIGN IN </h2></Button>
  </div>
)}
