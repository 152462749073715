export const certlinks = [
  {name: "PLAY CLASS",
  approval: "https://www.jotform.com/inbox/222774609345058",
  link: "https://form.jotform.com/222774609345058",
  view: "https://docs.google.com/spreadsheets/d/1fNaHdagM5ylvuPQIyeTdyiIJ4ZA2H99x2zQIVBriopg/edit?usp=sharing"},
  {name: "MUSIC CLASS",
  approval: "https://www.jotform.com/inbox/222820941946460",
  link: "https://form.jotform.com/222820941946460",
  view: "https://docs.google.com/spreadsheets/d/1XiVjFWEJPDDd6rJnA-3Nffv4D4mCU8oFkLXg3FyN2RI/edit?usp=sharing"},
  {name: "ART CLASS",
  approval: "https://www.jotform.com/inbox/222821333606449",
  link: "https://form.jotform.com/222821333606449",
  view: "https://docs.google.com/spreadsheets/d/1bGFgp7MO9FFJ7wGDxlkXZp9uj-dSWjtG26qaP6Zfyjo/edit?usp=sharing"},
  {name: "SMART CLASS",
  approval: "https://www.jotform.com/inbox/222821435356454",
  link: "https://form.jotform.com/222821435356454",
  view: "https://docs.google.com/spreadsheets/d/1cV7WC14jaHkht6auZxQdLLlJpqVBDwuZwA9UGRc-cSU/edit?usp=sharing"}
  ]