import React, {useState, useEffect} from "react";
import {firestoreDB} from "../firebase";
import {doc, getDoc} from "firebase/firestore";
import {Editor, EditorState, convertFromRaw} from "draft-js";

export default function FirebaseDownloader(props) {

  const [ content, setContent ] = useState("");
  let editorState;

  useEffect(() => {
    async function getData(){
      const downloadedContent = await getDoc(
        doc(firestoreDB, "lessonplans", props.which))
      setContent(downloadedContent.data());
    }
    getData();
  }, [props.which])

  if(content){editorState = EditorState.createWithContent(convertFromRaw(content))}

  return( 
    <>
      {editorState? <Editor editorState={editorState} readOnly={true}/> : ""}
    </>
  )
}
