export const progreplinkscirebonplayclass = [
  {name: "BABY", 
  progreplink: "https://form.jotform.com/222368998246472",
  replink: "https://docs.google.com/spreadsheets/d/1a2C_jegltDh0IEx5upTlN1OowUXtZs5EeISbd6B1xeE/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222368998246472"
  },
  {name: "CRAWLER",
  progreplink: "https://form.jotform.com/222751473480456",
  replink: "https://docs.google.com/spreadsheets/d/19xJQRGmwsoY8jmg4Yt6n7f--yw75AFY374NrowONf-I/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222751473480456" 
  },
  {name: "WALKER",
  progreplink: "https://form.jotform.com/222752477692466",
  replink: "https://docs.google.com/spreadsheets/d/1KrGfVfWE8ZeKeQxekz5BOqwrcZEE4S9xCW3ONrfjzto/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752477692466" 
  },
  {name: "RUNNER",
  progreplink: "https://form.jotform.com/222751819178464",
  replink: "https://docs.google.com/spreadsheets/d/1Fr6uh3vucFlrU5F7EVB4icRG0dVZxvKn_v9oqGtIvnU/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222751819178464" 
  },
  {name: "EXPLORER",
  progreplink: "https://form.jotform.com/222751786364464",
  replink: "https://docs.google.com/spreadsheets/d/1-SBfzyH93GSzlAyryfk3vuYQCWbNnlgXdv0ZnzAjpKc/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222751786364464"
  },
  {name: "ADVANCE",
  progreplink: "https://form.jotform.com/222752035168454",
  replink: "https://docs.google.com/spreadsheets/d/1dEKWM4EYRqtNLwOHK1DJSuJjle40d372SMj9wFfF0rA/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752035168454"
  },
  ]

export const progreplinkscirebonmusicclass = [
  {name: "MOZART",
  progreplink: "https://form.jotform.com/222752479756469",
  replink: "https://docs.google.com/spreadsheets/d/1DWarR1936GaaBPDbmJ3PbAxQT0QcKdfJqhSdnXXFBpk/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752479756469"
  },
  {name: "BEETHOVEN",
  progreplink: "https://form.jotform.com/222752286609462",
  replink: "https://docs.google.com/spreadsheets/d/1-SLoMzLjBI2SNXHQzxLcznCDZs0rop1CspjItbHVwCw/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752286609462" 
  },
  {name: "CHOPIN",
  progreplink: "https://form.jotform.com/222752377845465",
  replink: "https://docs.google.com/spreadsheets/d/1CqXbZKBqtecMHqWQuzwdkML08eeEOLWnrA1vvBpk3-g/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752377845465"
  },
  ]

export const progreplinkscirebonartclass = [
  {name: "DAVINCI",
  progreplink: "https://form.jotform.com/222753010141439",
  replink: "https://docs.google.com/spreadsheets/d/1m086HemN1ff5tpsqtrkJ07RuQEM54Y56Dx5bTQgpwpE/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222753010141439" 
  },
  {name: "PICASSO",
  progreplink: "https://form.jotform.com/222752879300458",
  replink: "https://docs.google.com/spreadsheets/d/1oeTUZcN7Elco18B4q6DGhwqjYrhy2j94wpNWMUa4q2M/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752879300458" 
  },
  {name: "REMBRANDT",
  progreplink: "https://form.jotform.com/222753147995467",
  replink: "https://docs.google.com/spreadsheets/d/1zPBJLfyo7h4_Ofv2_0Q5FRisPilxy8EbPWSrgpoSlW4/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222753147995467"},
  ]

export const progreplinkscirebonsmartclass = [
  {name: "SMART CLASS I",
  progreplink: "https://form.jotform.com/222752968753469",
  replink: "https://docs.google.com/spreadsheets/d/1oJKNT8rREcFxImYWMiQj2fUSNT4X_QdOLC-nJHhZzCU/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752968753469" 
  },
  {name: "SMART CLASS II",
  progreplink: "https://form.jotform.com/222752529199466",
  replink: "https://docs.google.com/spreadsheets/d/1dD6fgb4KIn7H9GTs18rJFAc2_f3kxG7WMBg8_qLjYmA/edit?usp=sharing",
  approval: "https://www.jotform.com/inbox/222752529199466" 
  },
  ]