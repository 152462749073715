import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";

export default function Footer() {
  return (
    <footer>
      <p>
        <span>
          <CopyrightIcon sx={{textAlign: "center"}}/>
        </span>
        2017 KINDERGYM INDONESIA
      </p>
      <p>All Rights Reserved</p>
    </footer>
  )
}
