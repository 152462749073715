import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import {ThemeProvider} from '@mui/material/styles';
import {StyledTableCell, StyledTableRow, theme} from "../shared/styledtable";
import {rows} from "./rows";
import {Link} from "react-router-dom";

const paperStyle = {
  marginTop: "10vh", 
  marginBottom: "2vh", 
  height: "85vh", 
  width: "97vw", 
  overflow: "hidden",
  opacity: "0.9"
}
const tableContainerStyle = {
  height: "85vh", 
  width:"97vw",
  opacity: 1
}

export function createData(
  week, baby, whichbaby, crawler, whichcrawler, walker, whichwalker,
  runner, whichrunner, explorer, whichexplorer, advance, whichadvance,
  mozart, whichmozart, beethoven, whichbeethoven, chopin, whichchopin, 
  davinci, whichdavinci, picasso, whichpicasso, rembrandt, whichrembrandt, 
  smart1, smart2) {
    return ({
      week, baby, whichbaby, crawler, whichcrawler, walker, whichwalker,
      runner, whichrunner, explorer, whichexplorer, advance, whichadvance,
      mozart, whichmozart, beethoven, whichbeethoven, chopin, whichchopin, 
      davinci, whichdavinci, picasso, whichpicasso, rembrandt, whichrembrandt, 
      smart1, smart2
    });
  }

export default function Rotation(props) {

  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper sx={paperStyle}>
          <TableContainer sx={tableContainerStyle}>
            <Table stickyHeader sx={{border: "1px solid", width: "fit-content"}}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell><h5>WEEK OF</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>BABY</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>CRAWLER</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>WALKER</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>RUNNER</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>EXPLORER</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>ADVANCE</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>MOZART</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>BEETHOVEN</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>CHOPIN</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>DAVINCI</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>PICASSO</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>REMBRANDT</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>SMART I</h5></StyledTableCell>
                  <StyledTableCell align="center"><h5>SMART II</h5></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody style={{height: "85vh"}}>
                {rows.map((row) => (
                  <StyledTableRow key={row.week}>
                    <StyledTableCell><h5>{row.week}</h5></StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichbaby}`}>
                        {row.baby}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichcrawler}`}>
                        {row.crawler}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichwalker}`}>
                        {row.walker}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichrunner}`}>
                        {row.runner}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichexplorer}`}>
                        {row.explorer}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichadvance}`}>
                        {row.advance}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichmozart}`}>
                        {row.mozart}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichbeethoven}`}>
                        {row.beethoven}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichchopin}`}>
                        {row.chopin}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichdavinci}`}>
                        {row.davinci}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichpicasso}`}>
                        {row.picasso}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={`/lessonplan/${row.whichrembrandt}`}>
                        {row.rembrandt}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.smart1}</StyledTableCell>
                    <StyledTableCell align="right">{row.smart2}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ThemeProvider>
    </>
  )
}
