import {createData} from "./rotation";

export const rows = [
  createData(
    "10/3", "Baby 11 (2)", "baby11", "Crawler 11 (2)", "crawler11", "Walker 11 (2)",
    "walker11", "Runner 11 (2)", "runner11", "Explorer 11 (2)", "explorer11", 
    "Advance 11 (2)", "advance11", "Classical (2)", "mozartclassical", 
    "Classical (2)", "beethovenclassical", "Classical (2)", 
    "chopinclassical", "Shapes (4)", "davincishapes4", "Shapes (4)", 
    "picassoshapes4", "Shapes (4)", "rembrandtshapes4", "tba", "tba"
    ),
  createData(
    "10/10", 
    "Baby 12 (1)", "baby12", "Crawler 12 (1)", "crawler12", "Walker12 (1)",
    "walker12", "Runner 12 (1)", "runner12", "Explorer 12 (1)", "explorer12", 
    "Advance 12 (1)", "advance12", 
    "Classical (3)", "mozartclassical", 
    "Classical (3)", "beethovenclassical", "Classical (3)", "chopinclassical", 
    "Textures (1)", "davincitextures1", "Circus (1)", "picassocircus1", 
    "Festival Fun (1)", "rembrandtfestivalfun1", "tba", "tba"
    ), 
  createData(
    "10/17", "Baby 12 (2)", "baby12", "Crawler 12 (2)", "crawler12", "Walker12 (2)",
    "walker12", "Runner 12 (2)", "runner12", "Explorer 12 (2)", "explorer12", 
    "Advance 12 (2)", "advance12", "Queen (1)", "mozartqueen", "Queen (1)", 
    "beethovenqueen", "Queen (1)", "chopinqueen", "Textures (2)", 
    "davincitextures2", "Circus (2)", "picassocircus2", "Festival Fun (2)", 
    "rembrandtfestivalfun2", "tba", "tba"
    ), 
  createData(
    "10/24", 
    "Baby 13 (1)", "baby13", "Crawler 13 (1)", "crawler13", "Walker13 (1)",
    "walker13", "Runner 13 (1)", "runner13", "Explorer 13 (1)", "explorer13", 
    "Advance 13 (1)", "advance13", 
    "Queen (2)", "mozartqueen", 
    "Queen (2)", "beethovenqueen", "Queen (2)", "chopinqueen", "Textures (3)", 
    "davincitextures3", "Circus (3)", "picassocircus3", "Festival Fun (3)", 
    "rembrandtfestivalfun3", "tba", "tba"
    ), 
  createData(
    "10/31", 
    "Baby 13 (2)", "baby13", "Crawler 13 (2)", "crawler13", "Walker13 (2)",
    "walker13", "Runner 13 (2)", "runner13", "Explorer 13 (2)", "explorer13", 
    "Advance 13 (2)", "advance13", 
    "African (1)", "mozartafrican", 
    "African (1)", "beethovenafrican", "African (1)", "chopinafrican", 
    "Textures (4)", "davincitextures4", "Circus (4)", "picassocircus4", 
    "Festival Fun (4)", "rembrandtfestivalfun4", "tba", "tba"
    ), 
  createData(
    "11/7", 
    "Baby 14 (1)", "baby14", "Crawler 14 (1)", "crawler14", "Walker14 (1)",
    "walker14", "Runner 14 (1)", "runner14", "Explorer 14 (1)", "explorer14", 
    "Advance 14 (1)", "advance14", 
    "African (2)", "mozartafrican", 
    "African (2)", "beethovenafrican", "African (2)", "chopinafrican", 
    "Winter (1)", "davinciwinter1", "Weather (1)", "picassoweather1", 
    "Art Fun (1)", "rembrandtartfun1", "tba", "tba"
    ), 
  createData(
    "11/14", 
    "Baby 14 (2)", "baby14", "Crawler 14 (2)", "crawler14", "Walker14 (2)",
    "walker14", "Runner 14 (2)", "runner14", "Explorer 14 (2)", "explorer14", 
    "Advance 14 (2)", "advance14", 
    "African (3)", "mozartafrican", 
    "African (3)", "beethovenafrican", "African (3)", "chopinafrican", 
    "Winter (2)", "davinciwinter2", "Weather (2)", "picassoweather2", 
    "Art Fun (2)", "rembrandtartfun2", "tba", "tba"
    ), 
  createData(
    "11/21", 
    "Baby 15 (1)", "baby15", "Crawler 15 (1)", "crawler15", "Walker15 (1)",
    "walker15", "Runner 15 (1)", "runner15", "Explorer 15 (1)", "explorer15", 
    "Advance 15 (1)", "advance15", 
    "N.W. Isles (1)", "mozartnwisles", 
    "N.W. Isles (1)", "beethovennwisles", "N.W. Isles (1)", "chopinnwisles", 
    "Winter (3)", "davinciwinter3", "Weather (3)", "picassoweather3",
    "Art Fun (3)", "rembrandtartfun3", "tba", "tba"
    ), 
  createData(
    "12/5", 
    "Baby 15 (2)", "baby15", "Crawler 15 (2)", "crawler15", "Walker15 (2)",
    "walker15", "Runner 15 (2)", "runner15", "Explorer 15 (2)", "explorer15", 
    "Advance 15 (2)", "advance15", 
    "N.W. Isles (2)", "mozartnwisles", 
    "N.W. Isles (2)", "beethovennwisles", "N.W. Isles (2)", "chopinnwisles", 
    "Winter(4)", "davinciwinter4", "Weather (4)", "picassoweather4",
    "Art Fun (4)", "rembrandtartfun4", "tba", "tba"
    ), 
  createData(
    "12/12", 
    "Baby 16 (1)", "baby16", "Crawler 16 (1)", "crawler16", "Walker16 (1)",
    "walker16", "Runner 16 (1)", "runner16", "Explorer 16 (1)", "explorer16", 
    "Advance 16 (1)", "advance16", 
    "N.W. Isles (3)", "mozartnwisles", 
    "N.W. Isles (3)", "beethovennwisles", "N.W. Isles (3)", "chopinnwisles", 
    "Pets(1)", "davincipets1", "Community Helpers (1)", 
    "picassocommunityhelpers1", "Zoom (1)", "rembrandtzoom1", "tba", "tba"
    ), 
  createData(
    "12/14", 
    "Baby 16 (2)", "baby16", "Crawler 16 (2)", "crawler16", "Walker16 (2)",
    "walker16", "Runner 16 (2)", "runner16", "Explorer 16 (2)", "explorer16", 
    "Advance 16 (2)", "advance16", 
    "R&B (1)", "mozartrandb", "R&B (1)", 
    "beethovenrandb", "R&B(1)", "chopinrandb", "Pets(2)", "davincipets2", 
    "Community Helpers (2)", "picassocommunityhelpers2", "Zoom (2)", 
    "rembrandtzoom2", "tba", "tba"
    ), 
  createData(
    "12/21", 
    "Baby 17 (1)", "baby17", "Baby 17 (1)", "crawler17", "Walker 17 (1)",
    "walker17", "Runner 17 (1)", "runner17", "Explorer 17 (1)", "explorer17", 
    "Advance 17 (1)", "advance17", 
    "R&B (2)", "mozartrandb", "R&B (2)", 
    "beethovenrandb", "R&B (2)", "chopinrandb", "Pets(3)", "davincipets3", 
    "Community Helpers (3)", "picassocommunityhelpers3", "Zoom (3)", 
    "rembrandtzoom3", "tba", "tba"
    ), 
  createData(
    "12/28", 
    "Baby 17 (2)", "baby17", "Crawler 17 (2)", "crawler17", "Walker 17 (2)",
    "walker17", "Runner 17 (2)", "runner17", "Explorer 17 (2)", "explorer17", 
    "Advance 17 (2)", "advance17", 
    "ABBA (1)", "mozartabba", "ABBA (1)", 
    "beethovenabba", "ABBA (1)", "chopinabba", "Pets(4)", "davincipets4", 
    "Community Helpers (4)", "picassocommunityhelpers4", "Zoom (4)", 
    "rembrandtzoom4", "tba", "tba"
    ), 
  createData(
    "1/2", 
    "Baby 1 (1)", "baby1", "Crawler 1 (1)", "crawler1", "Walker 1 (1)",
    "walker1", "Runner 1 (1)", "runner1", "Explorer 1 (1)", "explorer1", 
    "Advance 1 (1)", "advance1", "ABBA (2)", "mozartabba", "ABBA (2)",
    "beethovenabba", "ABBA (2)", "chopinabba", "Colors (1)", "davincicolors1", 
    "Insects & Bugs (1)", "picassoinsectsandbugs1", "Storyville (1)", 
    "rembrandtstoryville1", "tba", "tba"
    ), 
  createData(
    "1/9", 
    "Baby 1 (2)", "baby1", "Crawler 1 (2)", "crawler1", "Walker 1 (2)",
    "walker1", "Runner 1 (2)", "runner1", "Explorer 1 (2)", "explorer1", 
    "Advance 1 (2)", "advance1", 
    "Asia (1)", "mozartasia", "Asia (1)", 
    "beethovenasia", "Asia (1)", "chopinasia", "Colors (2)", "davincicolors2", 
    "Insects & Bugs (2)", "picassoinsectsandbugs2", "Storyville (2)", 
    "rembrandtstoryville2", "tba", "tba"
    ),
  createData(
    "1/16", 
    "Baby 2 (1)", "baby2", "Crawler 2 (1)", "crawler2", "Walker (1)",
    "walker2", "Runner 2 (1)", "runner2", "Explorer 2 (1)", "explorer2", 
    "Advance 2 (1)", "advance2", 
    "Asia (2)", "mozartasia", "Asia (2)", 
    "beethovenasia", "Asia (2)", "chopinasia", "Colors (3)", "davincicolors3", 
    "Insects & Bugs (3)", "picassoinsectsandbugs3", "Storyville (3)", 
    "rembrandtstoryville3", "tba", "tba"
    ),
  createData(
    "1/23", 
    "Baby 2 (2)", "baby2", "Crawler 2 (2)", "crawler2", "Walker 2 (2)",
    "walker2", "Runner 2 (2)", "runner2", "Explorer 2 (2)", "explorer2", 
    "Advance 2 (2)", "advance2", 
    "Asia (3)", "mozartasia", "Asia (3)", 
    "beethovenasia", "Asia (3)", "chopinasia", "Colors (4)", "davincicolors4", 
    "Insects & Bugs (4)", "picassoinsectsandbugs4", "Storyville (4)", 
    "rembrandtstoryville4", "tba", "tba"
    ),
  createData(
    "1/30", 
    "Baby 3 (1)", "baby3", "Crawler 3 (1)", "crawler3", "Walker 3 (1)",
    "walker3", "Runner 3 (1)", "runner3", "Explorer 3 (1)", "explorer3", 
    "Advance 3 (1)", "advance3", 
    "Divas (1)", "mozartdivas", "Divas (1)", 
    "beethovendivas", "Divas (1)", "chopindivas", "Spring (1)", "davincispring1", 
    "Earth (1)", "picassoearth1", "Science Fun (1)", "rembrandtsciencefun1", 
    "tba", "tba"
    ),
  createData(
    "2/6", 
    "Baby 3 (2)", "baby3", "Crawler 3 (2)", "crawler3", "Walker 3 (2)",
    "walker3", "Runner 3 (2)", "runner3", "Explorer 3 (2)", "explorer3", 
    "Advance 3 (2)", "advance3", 
    "Divas (2)", "mozartdivas", "Divas (2)", 
    "beethovendivas", "Divas (2)", "chopindivas", "Spring (2)", "davincispring2", 
    "Earth (2)", "picassoearth2", "Science Fun (2)", "rembrandtsciencefun2", 
    "tba", "tba"
    ),
  createData(
    "2/13", 
    "Baby 4 (1)", "baby4", "Crawler 4 (1)", "crawler4", "Walker 4 (1)",
    "walker4", "Runner 4 (1)", "runner4", "Explorer 4 (1)", "explorer4", 
    "Advance 4 (1)", "advance4", 
    "Rock & Roll (1)", "mozartrockandroll", 
    "Rock & Roll (1)", "beethovenrockandroll", "Rock & Roll (1)", 
    "chopinrockandroll", "Spring (3)", "davincispring3", "Earth (3)", 
    "picassoearth3", "Science Fun (3)", "rembrandtsciencefun3", "tba", "tba"
    ),
  createData(
    "2/20", 
    "Baby 4 (2)", "baby4", "Crawler 4 (2)", "crawler4", "Walker 4 (2)",
    "walker4", "Runner 4 (2)", "runner4", "Explorer 4 (2)", "explorer4", 
    "Advance 4 (2)", "advance4", 
    "Rock & Roll (2)", "mozartrockandroll", 
    "Rock & Roll (2)", "beethovenrockandroll", "Rock & Roll (2)", 
    "chopinrockandroll", "Spring (4)", "davincispring4", "Earth (4)", 
    "picassoearth4", "Science Fun (4)", "rembrandtsciencefun4", "tba", "tba"
    ),
  createData(
    "2/27", 
    "Baby 5 (1)", "baby5", "Crawler 5 (1)", "crawler5", "Walker (1)",
    "walker5", "Runner 5 (1)", "runner5", "Explorer 5 (1)", "explorer5", 
    "Advance 5 (1)", "advance5", 
    "Rock & Roll (3)", "mozartrockandroll", 
    "Rock & Roll (3)", "beethovenrockandroll", "Rock & Roll (3)", 
    "chopinrockandroll", "Summer Studio (1)", "davincisummerstudio1", 
    "Summer Studio (1)", "picassoearth1", "Summer Studio (1)", 
    "rembrandtsummerstudio1", "tba", "tba"
    ),
  createData(
    "3/6", 
    "Baby 5 (2)", "baby5", "Crawler 5 (2)", "crawler5", "Walker 5 (2)",
    "walker5", "Runner 5 (2)", "runner5", "Explorer 5 (2)", "explorer5", 
    "Advance 5 (2)", "advance5", 
    "Broadway (1)", "mozartbroadway1", 
    "Broadway (1)", "beethovenbroadway1", "Broadway (1)", "chopinbroadway1", 
    "Summer Studio (2)", "davincisummerstudio2", "Summer Studio (2)", 
    "picassosummerstudio2", "Summer Studio (2)", "rembrandtsummerstudio2", 
    "tba", "tba"
    ),
  createData(
    "3/14", 
    "Baby 6 (1)", "baby6", "Crawler 6 (1)", "crawler6", "Walker (1)",
    "walker6", "Runner 6 (1)", "runner6", "Explorer 6 (1)", "explorer6", 
    "Advance 6 (1)", "advance6", 
    "Broadway (2)", "mozartbroadway2", 
    "Broadway (2)", "beethovenbroadway2", "Broadway (2)", "chopinbroadway2", 
    "Summer Studio (3)", "davincisummerstudio3", "Summer Studio (3)", 
    "picassosummerstudio3", "Summer Studio (3)", "rembrandtsummerstudio3", 
    "tba", "tba"
    ),
  createData(
    "3/20", 
    "Baby 6 (2)", "baby6", "Crawler 6 (2)", "crawler6", "Walker (2)",
    "walker6", "Runner 6 (2)", "runner6", "Explorer 6 (2)", "explorer6", 
    "Advance 6 (2)", "advance6", 
    "Broadway (3)", "mozartbroadway3", 
    "Broadway (3)", "beethovenbroadway3", "Broadway (3)", "chopinbroadway3", 
    "Summer Studio (4)", "davincisummerstudio4", "Summer Studio (4)", 
    "picassosummerstudio4", "Summer Studio (4)", "rembrandtsummerstudio4", 
    "tba", "tba"
    ),
  createData(
    "3/27", 
    "Baby 7 (1)", "baby7", "Crawler 7 (1)", "crawler7", "Walker 7 (1)",
    "walker7", "Runner 7 (1)", "runner7", "Explorer 7 (1)", "explorer7", 
    "Advance 7 (1)", "advance7", 
    "Totally 80s (3)", "mozarttotally80s3", "Totally 80s (3)", 
    "beethoventotally80s3", "Totally 80s (3)", "chopintotally80s3", 
    "Transportation (1)", "davincitransportation1", "Transportation (1)", 
    "picassotransportation1", "Water Fun (1)", "rembrandtwaterfun1", 
    "tba", "tba"
    ),
]