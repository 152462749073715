import React, {useContext} from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import {LinkButton} from "../shared/tablelink";
import {ThemeProvider} from '@mui/material/styles';
import {certlinks} from "./certlinks";
import {StyledTableCell, StyledTableRow} from "../shared/styledtable";
import {theme} from "../shared/styledtable";
import {AuthContext} from "../contexts/authcontexts";
import {anEvenLighterShadeOfGrey} from "../styling/colors";

const tableContainerStyles = {width: "97vw", display: "flex", justifyContent: "center"}

export default function Certificate() {
 
  const {userPrivilege} = useContext(AuthContext);

  return (
    <>
      <ThemeProvider theme={theme}>
        <TableContainer sx={tableContainerStyles}>
          <Table id="certificate" sx={{width: "fit-content"}}>
            <TableHead sx={{backgroundColor: anEvenLighterShadeOfGrey}}>
              <StyledTableRow>
                <StyledTableCell align="center"><h5>CLASS</h5></StyledTableCell>
                <StyledTableCell align="center"><h5>CREATE</h5></StyledTableCell>
                <StyledTableCell align="center"><h5>VIEW</h5></StyledTableCell>
                {(userPrivilege.claims.coordinator || userPrivilege.claims.allacces)?
                <StyledTableCell align="center"><h5>APPROVAL</h5></StyledTableCell> : null}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {certlinks.map(cert => (
                <StyledTableRow key={cert.name}>
                  <StyledTableCell align="center"><h6>{cert.name}</h6></StyledTableCell>
                  <StyledTableCell align="center">
                    <LinkButton 
                      variant="text" 
                      onClick={() => window.open(cert.link, "_blank").focus()}>
                        MAKE 
                    </LinkButton>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <LinkButton 
                      variant="text" 
                      onClick={() => window.open(cert.view, "_blank").focus()}>
                        VIEW
                    </LinkButton>
                  </StyledTableCell>
                  {(userPrivilege.claims.coordinator || userPrivilege.claims.allacces)?
                  <StyledTableCell align="center">
                    <LinkButton 
                      variant="text" 
                      onClick={() => window.open(cert.approval, "_blank").focus()}>
                        APPROVAL
                    </LinkButton>
                  </StyledTableCell> : null}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
      </ThemeProvider>
    </>
  )
}
