import React, {useContext, useState, useEffect} from "react";
import Button from "@mui/material/Button";
import kindergymlogo from "../imgs/kindergymlogo.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ListItemButton from "@mui/material/ListItemButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../contexts/authcontexts";
import {ivory} from "../styling/colors";

const drawerStyle = {
  backgroundColor: ivory,
  height: "50vh",
  borderBottomRightRadius: "1rem",
  paddingRight: "0.5rem",
  display: "flex",
  justifyContent: "center"
}

export default function Navbar(props) {

  const [isMobile, setIsMobile] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  let mediaQuery = window.matchMedia('(max-width: 767px)');
  let navigate = useNavigate();
  //if user is online, allow viewing navbar, else show nothing
  const {viewNavbar} = useContext(AuthContext);

  useEffect(() => {if(mediaQuery.matches){setIsMobile(true)}else{setIsMobile(false)}}, [])
 
  function handleClick(direction){
    navigate(direction);
    setOpenDrawer(false);
  }  

  const navbarMenu = [
    {title: "HOME", fn: () => handleClick("/")},
    {title: "LESSON PLAN", fn: () => handleClick("/lessonplan")},
    {title: "PROGRESS REPORT", fn: () => handleClick("/progressreport")},
    {title: "CERTIFICATE", fn: () => handleClick("/certificate")},
    {title: "ATTENDANCE", fn: () => handleClick("/attendance")},
  ]

  return (
    <nav>{viewNavbar?
      <div> 
        {isMobile?
          <div>
            <Button onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuRoundedIcon id="mobileMenuIcon"/>
            </Button>
            <SwipeableDrawer
              open={openDrawer}
              PaperProps={{style: drawerStyle}}
              onClose={() => setOpenDrawer(false)}
              onOpen={() => setOpenDrawer(true)}>
                <div id="drawerMenu">
                  {navbarMenu.map(menu =>
                    <ListItemButton key={menu.title} onClick={menu.fn}>
                      <h2>{menu.title}</h2>
                    </ListItemButton>)}
                </div>
            </SwipeableDrawer>
          </div> :
          <div>{navbarMenu.map(menu => 
            <Button key={menu.title} variant="text" onClick={menu.fn}>
              <h2>{menu.title}</h2>
            </Button>)}
          </div>}
      </div> : null}
      <div>
        <Link to={"/"}>
          <img src={kindergymlogo} alt="Kindergym logo"/>
        </Link>
      </div>
    </nav>
  )
}
