import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {firebaseAuth} from "../firebase";
import {updatePassword} from "firebase/auth";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm, Controller} from "react-hook-form";
import {object, ref, string} from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "0.5rem"
};

export default function UpdatePassword(props) {

let checkPasswordSchema = object({
  newPassword: string().min(6).required(), 
  confirmPassword: string().oneOf([ref("newPassword")], "Password does not match").required("Required") 
});

const {handleSubmit, control, formState:{errors}} = useForm({
  defaultValues: {confirmPassword: "", newPassword: ""},
  resolver: yupResolver(checkPasswordSchema)
})

//open and close the modal
const [open, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);
const handleUpdatePassword = data => { 
  updatePassword(firebaseAuth.currentUser, data.confirmPassword)
  .then(() => {alert("Password changed"); setOpen(false)})
  .catch(err => alert("Failed to change password, contact HO"));
  }

return (
  <>
    <Button variant="contained" onClick={handleOpen}>
      CHANGE PASSWORD
    </Button>
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleUpdatePassword)}>
        <Box sx={style}>
          <Controller
            name="newPassword"
            control={control}
            render={({field}) => 
              <TextField 
                {...field}
                error={!!errors.newPassword}
                variant="outlined"
                label="Write a new password"
                helperText={errors.newPassword?.message}
                type="password"
                />}/>
          <Controller
            name="confirmPassword"
            control={control}
            render={({field}) =>
              <TextField
                {...field} 
                error={!!errors.confirmPassword} 
                variant="outlined" 
                label="Retype your password"
                helperText={errors.confirmPassword?.message}
                type="password"
                />}/>
          <Button variant="contained" type="submit">
            <h5>SUBMIT</h5>
          </Button>
        </Box>
      </form>
    </Modal>
  </>
)}
