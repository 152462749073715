import React from "react";
import kindergymlogotransparent from "../imgs/kindergymlogotransparent.png";
import FirebaseDownloader from "./firebasedownloader";
import {useParams} from "react-router-dom";

export default function LessonPlan(props) {

  const {id} = useParams();

  document.addEventListener("keydown", function(event){
    if(event.ctrlKey){
      event.preventDefault()}})

  return (
    <div
      style={{ 
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        marginTop: "8vh"
       }}>
      <div>
        <div className={props.boxForLessonPlan}>
        <img  
          src={kindergymlogotransparent} 
          style={{  
            width: "70vw", 
            zIndex: 1,
            rotate: "-30deg",
            position: "fixed",
            opacity: "0.4"
            }}/>
          <div 
            style={{
              color: "white",
              opacity: 0, 
              position: "fixed", 
              zIndex: 10000,
              width: "100%", 
              height: "100%",
              padding: "10rem"
              }}>
                copyright Kindergym Indonesia
          </div>
        <div id={props.disableText}>
          <FirebaseDownloader which={id}/>
        </div>
        </div>
      </div>
    </div>
  )
}
