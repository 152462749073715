import {styled, alpha} from "@mui/material/styles";
import Button from "@mui/material/Button";

export const LinkButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "6px 12px",
  lineHeight: 1.5,
  color: "black",
  fontFamily: [
    "poppins",
    "-apple-system",
    "BlinkMacSystemFont",
    "Roboto",
    "Arial",
    "sans-serif",
  ].join(","),
  "&:hover": {
    backgroundColor: alpha("#F7E6AD", 0.5),
    borderColor: alpha("#0062cc", 0.8),
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: alpha("#D0AF84", 0.5),
    borderColor: alpha("#005cbf", 0.8),
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

