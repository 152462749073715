import React, {useContext} from "react";
import Button from "@mui/material/Button";
import {AuthContext} from "../contexts/authcontexts";

const attendanceStyle = {
  height: "40vh",
  marginTop: "5vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}

const attendancelinks = [{
  edit: "https://form.jotform.com/222078486133457",
  view: "https://docs.google.com/spreadsheets/d/1VnQtELNse36I7xCYgR5CHUqfhI67tBm_ktZcaK8C6L4/edit?usp=sharing",
  review: "https://www.jotform.com/inbox/222078486133457/",
}];

function OpenNewTab(props) {
  return (
  <Button variant="contained" onClick={() => window.open(props.link, "_blank")}>
    {props.label}
  </Button>
  )
}

export default function Attendance(props) {

const classNames = `${props.flexVertically} ${props.centerVertically} ${props.centerHorizontally} ${props.gap}`
const {userPrivilege} = useContext(AuthContext);

  return (
    <div className={classNames}>
      <div id="attendance" className={props.box} style={attendanceStyle}>
        {attendancelinks.map(attendance => (
          <div key={attendance.edit} className={classNames}>
            <h1>ATTENDANCE REPORT</h1>
            <OpenNewTab label="MAKE" link={attendance.edit}/>
            <OpenNewTab label="DATA" link={attendance.view}/>
            {(userPrivilege.claims.coordinator || userPrivilege.claims.allacces)?
            <OpenNewTab label="REVIEW" link={attendance.review}/> : null}
          </div>
        ))}
      </div>
    </div>
  )
}

