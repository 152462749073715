import React, {useEffect, useState, useContext} from "react";
import {AuthContext} from "../contexts/authcontexts";
import {firebaseStorage} from "../firebase";
import {ref, getDownloadURL, listAll} from "firebase/storage";

export default function ImageViewer(props) {

const {isOnline} = useContext(AuthContext); 
const [profilePicURL, setProfilePicURL] = useState([]);

useEffect(() => {
  const imgRef = ref(firebaseStorage, `adminteacher/${isOnline}/`);
  listAll(imgRef)
  .then( res => {
    res.items.forEach( img => {
      getDownloadURL(img)
        .then(url => setProfilePicURL(url)) 
        .catch(error => console.log(error));
    })})}, [])

  return (
    <div style={{display: "flex", width: "100%"}}>
      <img src={profilePicURL} style={{margin: "auto", height: "200px"}}/>
    </div>
  )
}
